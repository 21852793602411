<template>
  <layout>
    <v-container fluid>
      <v-alert v-if="userDetails && !userDetails.enabled" color="error">
        Your account has not been validated yet, please try again later.
      </v-alert>
      <v-alert v-if="userDetails && userDetails.plan.type === 'Trial'" color="warning">
        Your trial account expires in {{planExpirationDays}} days
      </v-alert>
      <v-row>
        <v-col cols="12" md="6">
          <h1>Account</h1>
          <v-card
            class="mx-auto"
            tile
          >
              <v-row
                align="end"
                class="fill-height"
              >
                <v-col
                  class="pt-0"
                  cols="12"
                >
                  <v-avatar v-if="false"
                    class="profile"
                    color="grey"
                    size="164"
                    tile
                  >
                    <v-img :src="user.photoURL"></v-img>
                  </v-avatar>
                </v-col>
                <v-col class="py-0">
                  <v-list-item dark>
                    <v-list-item-icon>
                      <v-icon>mdi-account-badge-horizontal</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="title">
                        <v-badge color="teal" v-if="user.emailVerified">
                          <template v-slot:badge>
                            <v-icon>
                              mdi-check
                            </v-icon>
                          </template>
                          <span>{{user.email}}</span>
                        </v-badge>
                        <div v-else>
                          {{user.email}}
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn small v-on="on" @click="sendVerificationEmail">
                                Verify
                              </v-btn>
                            </template>
                            <span>Send verification email</span>
                          </v-tooltip>
                        </div>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{user.displayName}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-cake</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Creation date</v-list-item-title>
                      <v-list-item-subtitle>{{moment(user.metadata.creationTime).format('DD-MM-YYYY')}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Last login date</v-list-item-title>
                      <v-list-item-subtitle>{{moment(user.metadata.lastSignInTime).format('DD-MM-YYYY')}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            <v-card-actions>
              <v-btn color="primary" @click="passwordModal = true" text>Change password</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <h1>Plan</h1>
          <v-card
            class="mx-auto"
            tile
          >
            <v-row
              align="end"
              class="fill-height"
            >
              <v-col>
                <v-list-item dark>
                  <v-list-item-icon>
                    <v-icon>mdi-account-badge-horizontal</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Type</v-list-item-title>
                    <v-list-item-subtitle v-if="userDetails">{{userDetails.plan.type}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-cake</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Expiration date</v-list-item-title>
                    <v-list-item-subtitle v-if="userDetails">{{planExpirationDate.format('DD-MM-YYYY')}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
          <div v-if="1==2">
            <v-btn color="primary" @click="checkoutMonthly">
              Checkout Monthly
            </v-btn>
            <v-btn color="primary" @click="checkoutYearly">
              Checkout Yearly
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-snackbar :timeout="10000" v-model="snackbar" bottom right absolute :color="snackColor">
        {{snack}}
      </v-snackbar>
      <v-dialog v-model="passwordModal" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Change password</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="passwordForm" v-model="passwordFormValid">
              <v-text-field
                v-model="currentPassword"
                :rules="passwordRules"
                label="Current password"
                type="password"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                type="password"
              ></v-text-field>
              <v-text-field
                v-model="passwordConfirmation"
                :rules="passwordConfirmationRules"
                type="password"
                label="Password confirmation"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="error" text @click="passwordModal = false">Cancel</v-btn>
            <v-btn color="success" text @click="changePassword">Change password</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </layout>
</template>

<script>
import Layout from '../Layout';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export default {
  metaInfo: {
    title: 'Account'
  },
  data () {
    return {
      user: null,
      userDetails: null,
      userRef: null,
      snack: '',
      snackbar: false,
      snackColor: 'success',
      passwordModal: false,
      currentPassword: '',
      password: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password should be at least 6 characters'
      ],
      passwordConfirmationRules: [
        v => !!v || 'Password confirmation is required',
        v => (v && v === this.password) || 'Password do not match'
      ],
      passwordConfirmation: '',
      passwordFormValid: true
    };
  },
  components: {
    Layout
  },
  computed: {
    planExpirationDate () {
      return moment(this.userDetails.plan.expiresAt.seconds * 1000);
    },
    planExpirationDays () {
      let seconds = this.userDetails.plan.expiresAt.seconds - Date.now() / 1000;
      let days = seconds / 60 / 60 / 24;
      return Math.floor(days);
    }
  },
  methods: {
    moment (date) {
      return moment(date);
    },
    changePassword () {
      if (this.$refs.passwordForm.validate()) {
        let user = firebase.auth().currentUser;
        let cred = firebase.auth.EmailAuthProvider.credential(
          user.email, this.currentPassword
        );
        user.reauthenticateWithCredential(cred).then(() => {
          user.updatePassword(this.password).then(() => {
            this.snackColor = 'success';
            this.snack = 'Password sucessfully changed';
            this.snackbar = true;
          }).catch((error) => {
            this.snackColor = 'error';
            this.snack = error.message;
            this.snackbar = true;
          }).finally(() => {
            this.passwordModal = false;
          });
        }).catch((error) => {
          this.snackColor = 'error';
          this.snack = error.message;
          this.snackbar = true;
          this.passwordModal = false;
        });
      }
    },
    sendVerificationEmail () {
      firebase.auth().currentUser.sendEmailVerification().then(() => {
        this.snackColor = 'success';
        this.snack = 'Verification email has been sent';
        this.snackbar = true;
      }).catch((error) => {
        this.snackColor = 'error';
        this.snack = error.message;
        this.snackbar = true;
      });
    }
    /*
    ,
    checkoutMonthly () {
      var stripe = Stripe('pk_test_JPByzWqL4LtTIo7zo3wQQE6t');
      stripe.redirectToCheckout({
        items: [{ plan: 'plan_FnVFhVUu8LLoZd', quantity: 1 }],
        customerEmail: firebase.auth().currentUser.email,
        clientReferenceId: firebase.auth().currentUser.uid,
        successUrl: 'https://le-navet.com/account',
        cancelUrl: 'https://le-navet.com/account'
      })
        .then(function (result) {
          if (result.error) {
            console.log(result.error);
          }
        });
    },
    checkoutYearly () {
      var stripe = Stripe('pk_test_JPByzWqL4LtTIo7zo3wQQE6t');
      stripe.redirectToCheckout({
        items: [{ plan: 'plan_FnVG65V6GC1Y1K', quantity: 1 }],
        customerEmail: firebase.auth().currentUser.email,
        clientReferenceId: firebase.auth().currentUser.uid,
        successUrl: 'https://le-navet.com/account',
        cancelUrl: 'https://le-navet.com/account'
      })
        .then(function (result) {
          if (result.error) {
            console.log(result.error);
          }
        });
    }
    */
  },
  created: function () {
    this.user = firebase.auth().currentUser;
    this.userRef = firebase.firestore().collection('users').doc(this.user.uid);
    this.userRef.get().then(snapshotQuery => {
      this.userDetails = snapshotQuery.data();
    });
  }
};
</script>
